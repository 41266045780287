import React, { useState, useEffect, useRef, useCallback} from 'react';
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FaAddressBook,
  FaBars,
  FaBriefcase,
  FaBuilding,
  FaChartLine,
  FaCog,
  FaDiceD6,
  FaHandshake,
  FaIndustry,
  FaSignOutAlt,
  FaTachometerAlt,
  FaUserFriends,
  FaUsers,
  FaTasks,
  FaAngleUp,
  FaAngleDown,
} from 'react-icons/fa';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { IoSettingsOutline } from "react-icons/io5";

import LeadList from '../pages/leads/Leads';
import {
  Navigate,
  redirect,
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import AddContacts from '../pages/contacts/AddContacts';
import LeadDetails from '../pages/leads/LeadDetails';
import Contacts from '../pages/contacts/Contacts';
import ContactDetails from '../pages/contacts/ContactDetails';
import Users from '../pages/users/Users';

import Status from '../pages/status/Status';
import { AddStatus } from '../pages/status/AddStatus';
import { EditStatus } from '../pages/status/EditStatus';

import Source from '../pages/source/Source';
import { AddSource } from '../pages/source/AddSource';
import { EditSource } from '../pages/source/EditSource';

import Opportunities from '../pages/opportunities/Opportunities';
import Cases from '../pages/cases/Cases';
import logo from '../assets/images/auth/img_logo.png';
import { AddLeads } from '../pages/leads/AddLeads';
import Accounts from '../pages/accounts/Accounts';
import { AddAccount } from '../pages/accounts/AddAccounts';
import { AccountDetails } from '../pages/accounts/AccountDetails';
import { AddUsers } from '../pages/users/AddUsers';
import { AddOpportunity } from '../pages/opportunities/AddOpportunity';
import { OpportunityDetails } from '../pages/opportunities/OpportunityDetails';
import { AddCases } from '../pages/cases/AddCases';
import { css } from '@emotion/css';
import { StyledListItemButton, StyledListItemText } from '../styles/CssStyled';
// import MyContext, { MyContextData } from '../context/Context';
import MyContext from '../context/Context';
import { Home } from '../pages/home/Home';
import EditContact from '../pages/contacts/EditContacts';
import { fetchData } from './FetchData';
import { OrgUrl, ProfileUrl, RefreshToken } from '../services/ApiUrls';
import OrganizationModal from '../pages/organization/OrganizationModal';
import { EditUser } from '../pages/users/EditUser';
import Company from '../pages/company/Company';
import AddCompany from '../pages/company/AddCompany';
import CompanyDetails from '../pages/company/CompanyDetails';
import EditCompany from '../pages/company/EditCompany';
import { EditLead } from '../pages/leads/EditLead';
import UserDetails from '../pages/users/UserDetails';
import '../styles/style.css';
import { black } from 'material-ui/styles/colors';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ClassNames } from '@emotion/react';
import Tasks from '../pages/tasks/Tasks';
import { SERVER } from '../services/ApiUrls';
import BoardTasks from '../pages/tasks/BoardTasks';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeedbackForm from './FeeebackForm';
import ChatIcon from '@mui/icons-material/Chat';
// import FaUserFriends from 'react-icons/fa/user-friends'; // Adjust this import based on your actual icon source


// declare global {
//     interface Window {
//         drawer: any;
//     }
// }

interface UserDetails {
  user_details: {
    profile_pic?: string;
    email: string;
  };
}

interface Item {
  org: {
    id: any;
    name: any;
  };
}

export default function Sidebar(props: any) {

  // State to manage the expanded state of the accordion
  const [expanded, setExpanded] = useState<string | false>(false);

  // Function to handle accordion panel changes
  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  
  const navigate = useNavigate();
  const location = useLocation();
  const [screen, setScreen] = useState('leads');
  const [module, setModule] = useState([]);
  // const [login, setLogin] = useState(true)
  const [drawerWidth, setDrawerWidth] = useState(200);
  const [headerWidth, setHeaderWidth] = useState(drawerWidth);
  const [userDetail, setUserDetail] = useState<UserDetails | null>(null);
  const [organizationModal, setOrganizationModal] = useState(false);
  const organizationModalClose = () => {
    setOrganizationModal(false);
  };

  const [userProfilePic, setUserProfilePic] = useState<string>('');
  const [userEmailInitial, setUserEmailInitial] = useState<string>('');

  const TokenRefreshThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds

  const [tokenExpiration, setTokenExpiration] = useState<any>("");
  const [screenActive, setScreenActive] = useState<any>(true);

  useEffect(() => {
    const storedExpiration = localStorage.getItem('access_token_expiration_time');
    if (storedExpiration) {
      setTokenExpiration(storedExpiration);
    }
  });

  const calculateRemainingTime = () => {
    const expirationTime = new Date(tokenExpiration).getTime();
    const currentTime = Date.now();
    return Math.max(0, expirationTime - currentTime);
  };

  const logout = () => {
    localStorage.clear();
    window.location.pathname = '/login';
  };

  useEffect(() => {
    const remainingTime = calculateRemainingTime();

    if (remainingTime > 0) {
      setTimeout(() => {
        logout();
      }, remainingTime);
    } 
  }, [tokenExpiration]);

  const refreshToken = async () => {
    const bodyRefresh = {
      refresh_token: localStorage.getItem('refresh'),
    };
    try {
      const res = await fetchData(`${RefreshToken}/`, 'POST', JSON.stringify(bodyRefresh), headers);
      if (res.access) {
        localStorage.setItem('Token', `Bearer ${res.access}`);
        localStorage.setItem('access_token_expiration_time', res.access_token_expiration_time);
        setTokenExpiration(res.access_token_expiration_time); // Update tokenExpiration state
      }
    } catch (error) {
      console.error('Token refresh error:', error);
      logout();
    }
  };

  const handleVisibilityChange = () => {
    const remainingTime = calculateRemainingTime();
    // console.log('Remaining time', remainingTime, "<=", TokenRefreshThreshold);
    if (screenActive && remainingTime <= TokenRefreshThreshold) {
      refreshToken();
    }
  };

  useEffect(() => {
    const handleActivity = () => {
      setScreenActive(true);
      handleVisibilityChange();
    };

    let activityTimeout: NodeJS.Timeout;

    // Add event listeners for user activity
    const activityEvents = ['mousemove', 'keydown', 'click'];
    activityEvents.forEach(event => {
      document.addEventListener(event, handleActivity);
    });

    return () => {
      activityEvents.forEach(event => {
        document.removeEventListener(event, handleActivity);
      });
      clearTimeout(activityTimeout);
    };
  });


  useEffect(() => {
    if (userDetail) {
      if (userDetail.user_details.profile_pic) {
        setUserProfilePic(userDetail.user_details.profile_pic);
        // console.log("Img URL", userDetail.user_details.profile_pic);
        
      } else if (userDetail.user_details.email) {
        const initials = userDetail.user_details.email
          .slice(0, 1)
          .toUpperCase();
        setUserEmailInitial(initials);
        // console.log("Name", initials);
      }
    }
  }, [userDetail]);

  useEffect(() => {
    toggleScreen();
  }, [navigate]);
  // useEffect(() => {
  // navigate('/leads')
  // if (localStorage.getItem('Token') && localStorage.getItem('org')) {
  //     // setScreen('contacts')
  //     navigate('/contacts')
  // }
  // if (!localStorage.getItem('Token')) {
  //     navigate('/login')
  // }
  // if (!localStorage.getItem('org')) {
  //     navigate('/organization')
  // }
  // toggleScreen()
  // }, [])


  const [organization, setOrganization] = useState<Item[]>([]);
  const [selectedOrg, setSelectedOrg] = useState(localStorage.getItem('orgName') || location.state?.orgName);

  const headerss = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
  };

  const getOrganization = () => {
    fetchData(`${OrgUrl}/`, 'GET', null as any, headerss)
      .then((res: any) => {
        console.log(res, 'org')
        console.log(SERVER)
        if (res?.profile_org_list) {
          setOrganization(res?.profile_org_list);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  useEffect(() => {
    getOrganization();
  }, []);

  const toggleScreen = () => {
    // console.log(location.pathname.split('/'), 'll')
    if (
      location.pathname.split('/')[1] === '' ||
      location.pathname.split('/')[1] === undefined ||
      location.pathname.split('/')[2] === 'leads'
    ) {
      setScreen('leads');
    } else if (location.pathname.split('/')[2] === 'leads') {
      setScreen('leads');
    } else if (location.pathname.split('/')[2] === 'tasks') {
      setScreen('tasks');
    } else if (location.pathname.split('/')[2] === 'opportunities') {
      setScreen('opportunities');
    } else if (location.pathname.split('/')[2] === 'employers') {
      setScreen('employers');
    } else if (location.pathname.split('/')[2] === 'companies') {
      setScreen('companies');
    } else if (location.pathname.split('/')[2] === 'users') {
      setScreen('users');
    }else if (location.pathname.split('/')[2] === 'contacts') {
      setScreen('contacts');
    } else if (location.pathname.split('/')[2] === 'accounts') {
      setScreen('accounts');
    } else if (location.pathname.split('/')[2] === 'status') {
      setScreen('status');
    } else if (location.pathname.split('/')[2] === 'source') {
      setScreen('source');
    }
  };

  useEffect(() => {
    userProfile();
  }, []);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
    org: localStorage.getItem('org'),
  };

  const userProfile = () => {
    fetchData(`${ProfileUrl}/`, 'GET', null as any, headers)
      .then((res: any) => {
        // console.log(res, 'user')
        if (res?.user_obj) {
          setUserDetail(res?.user_obj);
          console.log('userDetails', res?.user_obj);
          //   debugger;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // const navList = ['leads', 'patients', 'tasks', 'users'];
  const navList = ['leads','accounts', 'tasks'];
  const accordionNavList = ['users', 'status', 'source'];
  //   const navList = [
  //     'leads',
  //     'contacts',
  //     'opportunities',
  //     'accounts',
  //     'companies',
  //     'users',
  //   ];
  const navIcons = (text: any, screen: any): React.ReactNode => {
    switch (text) {
      case 'leads':
        return screen === 'leads' ? <FaUsers fill="#3e79f7" /> : <FaUsers />;
      case 'contacts':
        return screen === 'contacts' ? (
          <FaAddressBook fill="#3e79f7" />
        ) : (
          <FaAddressBook />
        );
      case 'accounts':
        return screen === 'accounts' ? (
          <FaBuilding fill="#3e79f7" />
        ) : (
          <FaBuilding />
        );
      case 'tasks':
        return screen === 'tasks' ? (
          <FaTasks fill="#3e79f7" />
        ) : (
          <FaTasks />
        );
      //   case 'opportunities':
      //     return screen === 'opportunities' ? (
      //       <FaHandshake fill="#3e79f7" />
      //     ) : (
      //       <FaHandshake />
      //     );
      // case 'employers':
      //   return screen === 'employers' ? (
      //     <FaBuilding fill="#3e79f7" />
      //   ) : (
      //     <FaBuilding />
      //   );
        // case 'companies':
        //   return screen === 'companies' ? (
        //     <FaIndustry fill="#3e79f7" />
        //   ) : (
        //     <FaIndustry />
        //   );
      // case 'analytics':
      //     return screen === 'analytics' ? <FaChartLine fill='#3e79f7' /> : <FaChartLine />
      case 'users':
        return screen === 'users' ? (
          <FaUserFriends fill="#3e79f7" />
        ) : (
          <FaUserFriends />
        );

      case 'status':
        return screen === 'status' ? (
          <FilterListIcon  className='sidebarSettingsIcons selectedSidebarSettingsIcon'/>
        ) : (
          <FilterListIcon  className='sidebarSettingsIcons'/>
        );
      case 'source':
        return screen === 'source' ? (
          <AccountCircleOutlinedIcon  className='sidebarSettingsIcons selectedSidebarSettingsIcon'/>
        ) : (
          <AccountCircleOutlinedIcon  className='sidebarSettingsIcons'/>
        );
      // case 'cases':
      //     return screen === 'cases' ? <FaBriefcase fill='#3e79f7' /> : <FaBriefcase />
      default:
        return <FaDiceD6 fill="#3e79f7" />;
    }
  };

  // useEffect(() => {
  //   console.log(props.organization[0]?.org?.name)
  // }, [])

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // console.log(screen, 'sidebar');

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const dropdownOrgHandler = (id: any, name: any) => {
    localStorage.setItem('org', id);
    localStorage.setItem('orgName', name);
    setIsDropdownVisible(false);
    setSelectedOrg(name);
    navigate('/app/leads');
    window.location.reload();
  }

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownVisible(false);
      }
      if (dropdownRef.current) {
        console.log('Clicked:', event.target);
        console.log('Dropdown:', dropdownRef.current);
    
        if (!dropdownRef.current.contains(event.target as Node)) {
          console.log('Click outside dropdown. Closing dropdown...');
          setIsDropdownVisible(false);
        } else {
          console.log('Click inside dropdown. Keeping it open.');
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  //For small device
  const [isDropdownVisibleMobile, setIsDropdownVisibleMobile] = useState(false);

  const toggleDropdownMobile = () => {
    setIsDropdownVisibleMobile((prev) => !prev);
  };

  const dropdownOrgHandlerMobile = (id: any, name: any) => {
    localStorage.setItem('org', id);
    localStorage.setItem('orgName', name);
    setIsDropdownVisible(false);
    setSelectedOrg(name);
    navigate('/app/leads');
    window.location.reload();
  }

  const dropdownRefMobile = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClickMobile = (event: MouseEvent) => {
      if (dropdownRefMobile.current && !dropdownRefMobile.current.contains(event.target as Node)) {
        setIsDropdownVisibleMobile(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClickMobile);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClickMobile);
    };
  }, []);

  //Feedback
  
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const openFeedbackModal = () => {
    setIsFeedbackModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setIsFeedbackModalOpen(false);
  };

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: '60px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            // boxShadow: 'none',
            // borderBottom: `0.5px solid gray`
            boxShadow: '1px',
          }}
          className='appBarContainer'
        >
          <Box className="navbarLogoBarContainer">
            <Toolbar>
                <div className='logoMenuBarContainer' style={{display: 'flex', alignItems: 'center'}}>
                  {drawerWidth === 60 ? (
                    <img
                      src={logo}
                      width={'125px'}
                      height={'20px'}
                      style={{
                        marginLeft: '-15px',
                        marginRight: '10px',
                      }}
                      className='smallLogo'
                    />
                  ) : (
                    <img
                      src={logo}
                      width={'140px'}
                      height={'23px'}
                      style={{ marginLeft: '-5px', marginRight: '30px' }}
                      className='bigLogo'
                    />
                )}
                <IconButton
                  sx={{ ml: '-10px' }}
                  onClick={() => setDrawerWidth(drawerWidth === 60 ? 200 : 60)}
                >
                  <FaBars style={{ height: '20px' }} />
                </IconButton>
              </div>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: 'black',
                  ml: '20px',
                  textTransform: 'capitalize',
                }}
                className='screenName'
              >
                {screen}
                {/* Bottle-CRM */}
              </Typography>
            </Toolbar>
          </Box>
          <div style={{display: "flex", alignItems:"center", gap: "20px"}} className='orgAvatarContainer'>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <div className="dropdown orgNavbarDropdown">
                <div style={{display: "flex", alignItems:"center", gap: '15px'}}>
                  <span style={{color: "#1a3353", fontWeight: "700", fontSize: "16px"}}>{selectedOrg}</span>
                  {isDropdownVisible ? <FaAngleUp fill="#1a3353" style={{cursor: "pointer", paddingTop: "3px"}}/> : <FaAngleDown fill="#1a3353" onClick={toggleDropdown} style={{cursor: "pointer", paddingTop: "3px"}}/>}
                </div>
                
                {isDropdownVisible &&
                  <div className="dropdown-content" ref={dropdownRef}>
                    {organization.map((org: any) => (
                        <div key={org.org.id} className="orgNameDiv">
                          <span onClick={() => dropdownOrgHandler(org.org.id, org.org.name)}>{org.org.name}</span>
                        </div>                
                    ))}
                    <div style={{height: "2px", width: "100%", backgroundColor: "grey"}}></div>
                    <div className="addOrgNameDiv" onClick={() => setOrganizationModal(!organizationModal)}>
                      +
                    </div>
                  </div>
                }
              </div>
            </div>                     
          {/* <NotificationsNoneIcon style={{color: "#1A3353", cursor: "pointer"}}/> */}
          <Box
            style={{
              marginRight: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {/* <IconButton onClick={userProfile} sx={{ mr: 2 }}><FaCog /></IconButton> */}
            <IconButton onClick={handleClick} sx={{ mr: 3 }}>
              {userProfilePic ? (
                <Avatar
                src={userProfilePic}
                sx={{
                  height: '35px',
                  width: '35px',
                }}
              />
              ) : (
                <Avatar
                  sx={{
                    height: '35px',
                    width: '35px',
                    backgroundColor: '#3e79f7',
                  }}
                >
                  {userEmailInitial}
                </Avatar>
              )}
            </IconButton>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <List disablePadding>
                <ListItem disablePadding>
                  <StyledListItemButton
                    onClick={() => {
                      localStorage.clear();
                      navigate('/login');
                    }}
                  >
                    <ListItemIcon>
                      {' '}
                      <FaSignOutAlt fill="#3e79f7" />
                    </ListItemIcon>
                    <StyledListItemText
                      primary={'Sign out'}
                      sx={{ ml: '-20px', color: '#3e79f7' }}
                    />
                  </StyledListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <StyledListItemButton
                    onClick={() => setOrganizationModal(!organizationModal)}
                  >
                    <ListItemIcon>
                      {' '}
                      <FaIndustry fill="#3e79f7" />
                    </ListItemIcon>
                    <StyledListItemText
                      primary={'Organization'}
                      sx={{ ml: '-20px', color: '#3e79f7' }}
                    />
                  </StyledListItemButton>
                </ListItem>
              </List>
              {/* <Tooltip title='logout' sx={{ ml: '15px' }}>
                                <IconButton
                                    >
                                </IconButton>
                            </Tooltip> */}
            </Popover>
          </Box>
          </div>
          
          {/* For small devices */}
          <Box className='hiddenAppBar'>
            <Toolbar>
                <div className='logoMenuBarContainer' style={{display: 'flex', alignItems: 'center'}}>
                  {drawerWidth === 60 ? (
                    <img
                      src={logo}
                      width={'125px'}
                      height={'20px'}
                      className='smallLogo'
                    />
                  ) : (
                    <img
                      src={logo}
                      width={'140px'}
                      height={'23px'}
                      className='bigLogo'
                    />
                )}
                
                </div>
            </Toolbar>
          </Box>

          {/* <div className='feedbackContainer' onClick={openFeedbackModal}>
            <ChatIcon className='feedbackIcon'/> Feedback
          </div>  */}
          
          {isFeedbackModalOpen && <FeedbackForm onClose={closeFeedbackModal} />}

          <div className='org_AvatarContainer'>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div className="dropdown orgDropdownContainer">
                  <div style={{display: "flex", alignItems:"center", gap: '15px'}} className='orgArrowContainer'>
                    <span style={{color: "#1a3353", fontWeight: "700", fontSize: "16px"}} className='selectedOrg'>{selectedOrg}</span>
                    {isDropdownVisibleMobile ? <FaAngleUp fill="#1a3353" style={{cursor: "pointer", paddingTop: "3px"}}/> : <FaAngleDown fill="#1a3353" onClick={toggleDropdownMobile} style={{cursor: "pointer", paddingTop: "3px"}}/>}
                  </div>
                  
                  {isDropdownVisibleMobile &&
                    <div className="dropdown-content" ref={dropdownRefMobile}>
                      {organization.map((org: any) => (
                          <div key={org.org.id} className="orgNameDiv">
                            <span onClick={() => dropdownOrgHandlerMobile(org.org.id, org.org.name)}>{org.org.name}</span>
                          </div>                
                      ))}
                      <div style={{height: "2px", width: "100%", backgroundColor: "grey"}}></div>
                      <div className="addOrgNameDiv" onClick={() => setOrganizationModal(!organizationModal)}>
                        +
                      </div>
                    </div>
                  }
                </div>
            </div> 
            
            <Box style={{ marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }} className='hiddenAppBar hiddenAvatar'>
              {/* <IconButton onClick={userProfile} sx={{ mr: 2 }}><FaCog /></IconButton> */}
              <IconButton onClick={handleClick} sx={{ mr: 3 }}>
                {userProfilePic ? (
                  <Avatar
                  src={userProfilePic}
                  sx={{
                    height: '35px',
                    width: '35px',
                  }}
                />
                ) : (
                  <Avatar
                    sx={{
                      height: '35px',
                      width: '35px',
                      backgroundColor: '#3e79f7',
                    }}
                  >
                    {userEmailInitial}
                  </Avatar>
                )}
              </IconButton>
              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <List disablePadding>
                  <ListItem disablePadding>
                    <StyledListItemButton
                      onClick={() => {
                        localStorage.clear();
                        navigate('/login');
                      }}
                    >
                      <ListItemIcon>
                        {' '}
                        <FaSignOutAlt fill="#3e79f7" />
                      </ListItemIcon>
                      <StyledListItemText
                        primary={'Sign out'}
                        sx={{ ml: '-20px', color: '#3e79f7' }}
                      />
                    </StyledListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <StyledListItemButton
                      onClick={() => setOrganizationModal(!organizationModal)}
                    >
                      <ListItemIcon>
                        {' '}
                        <FaIndustry fill="#3e79f7" />
                      </ListItemIcon>
                      <StyledListItemText
                        primary={'Organization'}
                        sx={{ ml: '-20px', color: '#3e79f7' }}
                      />
                    </StyledListItemButton>
                  </ListItem>
                </List>
                {/* <Tooltip title='logout' sx={{ ml: '15px' }}>
                                  <IconButton
                                      >
                                  </IconButton>
                              </Tooltip> */}
              </Popover>
            </Box>
          </div>
        </AppBar>

        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          className='sidebarDrawer'
        >
          <Box>
            <List sx={{ pt: '65px' }}>
              {/* {navList.map((text, index) => (
                <ListItem key={text} disablePadding>
                  <StyledListItemButton
                    sx={{ pt: '6px', pb: '6px' }}
                    onClick={() => {
                      navigate(`/app/${text}`);
                      setScreen(text);
                    }}
                    selected={screen === text}
                  >
                    <ListItemIcon sx={{ ml: '5px' }}>
                      {navIcons(text, screen)}
                    </ListItemIcon>
                    <StyledListItemText
                      primary={text}
                      sx={{ ml: -2, textTransform: 'capitalize' }}
                    />
                  </StyledListItemButton>
                </ListItem>
              ))} */}
               {navList.map((text, index) => {
                  return (
                    <ListItem key={text} disablePadding>
                      <Tooltip title={text === 'tasks' ? 'COMING SOON' : ''} arrow>
                        <StyledListItemButton
                          sx={{ pt: '6px', pb: '6px' }}
                          onClick={() => {
                            if (text !== 'tasks') {
                              navigate(`/app/${text}`);
                              setScreen(text);
                            }
                          }}
                          selected={screen === text}
                          aria-disabled={text === 'tasks'}
                        >
                          <ListItemIcon sx={{ ml: '5px' }}>
                            {navIcons(text, screen)}
                          </ListItemIcon>
                          <StyledListItemText
                            primary={text}
                            sx={{ ml: -2, textTransform: 'capitalize' }}
                          />
                        </StyledListItemButton>
                      </Tooltip>
                    </ListItem>
                  );
                })}
                <ListItem disablePadding>
                  <Accordion
                    expanded={expanded === 'usersAccordion'}
                    onChange={handleChange('usersAccordion')}
                    className='accordionSidebar'
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon className='expandIcon'/>}>
                      <ListItemIcon className='settingsIcon'>
                        <IoSettingsOutline />
                      </ListItemIcon>
                      <StyledListItemText primary="Settings"  style={{marginRight: '30px'}}/>
                    </AccordionSummary>
                    <AccordionDetails style={{padding: '0'}}>
                      {accordionNavList.map((text, index) => {
                        return (
                          <ListItem key={text} disablePadding>
                              <StyledListItemButton
                                sx={{ pt: '6px', pb: '6px' }}
                                onClick={() => {
                                    navigate(`/app/${text}`);
                                    setScreen(text);
                                }}
                                selected={screen === text}
                              >
                                <ListItemIcon sx={{ ml: '5px' }}>
                                  {navIcons(text, screen)}
                                </ListItemIcon>
                                <StyledListItemText
                                  primary={text}
                                  sx={{ ml: -2, textTransform: 'capitalize' }}
                                />
                              </StyledListItemButton>
                          </ListItem>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
              </ListItem> 
            </List>
          </Box>
        </Drawer>
        <MyContext.Provider value={drawerWidth}>
          {/* <Box sx={{ width: drawerWidth === 60 ? '1380px' : '1240px', ml: drawerWidth === 60 ? '60px' : '200px', overflowX: 'hidden' }}> */}
          <Box
            sx={{
              width: 'auto',
              ml: drawerWidth === 60 ? '60px' : '200px',
              overflowX: 'hidden',
            }}
          >
            {/* {location.pathname.split('/')[1] === '' && <Contacts />}
                {location.pathname.split('/')[1] === 'contacts' && <Contacts />}
                {location.pathname.split('/')[2] === 'add-contacts' && <AddContacts />}
                {location.pathname.split('/')[1] === 'leads' && <LeadList />}
                {location.pathname.split('/')[2] === 'add-leads' && <AddLeads />} */}
            {/* {location.pathname === 'leads' && <LeadList />}
                        {screen === 'contacts' && <Contacts />} */}
            {/* <Routes>
                            <Route index element={<Navigate to="/contacts" replace />} />
                            </Routes> */}
            <Routes>
              {/* <Route index element={<Contacts />} /> */}
              {/* <Route path='/' element={<Contacts />} /> */}
              <Route index element={<LeadList />} />
              <Route path="/app/leads" element={<LeadList />} />
              <Route path="/app/leads/add-leads" element={<AddLeads />} />
              <Route path="/app/leads/edit-lead" element={<EditLead />} />
              <Route path="/app/leads/lead-details" element={<LeadDetails />} />
              <Route path="/app/accounts" element={<Company />} />
              <Route
                path="/app/accounts/add-account"
                element={<AddCompany />}
              />
              <Route
                path="/app/accounts/edit-account"
                element={<EditCompany />}
              />
              <Route
                path="/app/accounts/account-details"
                element={<CompanyDetails />}
              />
              <Route path="/app/contacts" element={<Contacts />} />
              <Route
                path="/app/contacts/add-contact"
                element={<AddContacts />}
              />
              <Route
                path="/app/contacts/contact-details"
                element={<ContactDetails />}
              />
              <Route
                path="/app/contacts/edit-contact"
                element={<EditContact />}
              />
              { selectedOrg === "Task" && <Route path="/app/tasks" element={<Tasks />} />}
              <Route path="/app/tasks/tasks-board" element={<BoardTasks />} />
              <Route path="/app/employers" element={<Accounts />} />
              <Route
                path="/app/employers/add-account"
                element={<AddAccount />}
              />
              <Route
                path="/app/employers/account-details"
                element={<AccountDetails />}
              />
        
              <Route path="/app/users" element={<Users />} />
              <Route path="/app/users/add-users" element={<AddUsers />} />
              <Route path="/app/users/edit-user" element={<EditUser />} />
              <Route path="/app/users/user-details" element={<UserDetails />} />
              <Route path="/app/opportunities" element={<Opportunities />} />
              <Route
                path="/app/opportunities/add-opportunities"
                element={<AddOpportunity />}
              />
              <Route
                path="/app/opportunities/opportunity-details"
                element={<OpportunityDetails />}
              />
              <Route path="/app/cases" element={<Cases />} />
              <Route path="/app/add-cases" element={<AddCases />} />
              
              
              <Route path="/app/status" element={<Status />} />
              <Route path="/app/status/edit-status" element={<EditStatus />} />
              <Route path="/app/status/add-status" element={<AddStatus />} />

              <Route path="/app/source" element={<Source />} />
              <Route path="/app/source/add-source" element={<AddSource />} />
              <Route path="/app/source/edit-source" element={<EditSource />} />
              
            </Routes>
            {/* <Route path='/cases/add-cases' element={<AddCases />} />
                            <Route path='/cases/cases-details' element={<CasesDetails />} />
                            <Route path='/analytics' element={<Analytics />} /> */}

            {/* 

            <Route path='/accounts' element={<Account />} />
            <Route path='/analytics' element={<Analytics />} />
            <Route path='/users' element={<Users />} />
            <Route path='/leads/add-leads' element={<AddLeads />} />
            <Route path='/leads/lead-details' element={<LeadDetails />} />
            <Route path='/leads/edit-leads' element={<EditLead />} />
            <Route path='/contacts/add-contacts' element={<AddContact />} />
            <Route path='/contacts/contact-details' element={<ContactDetails />} />
            <Route path='/contacts/edit-contacts' element={<EditContact />} />
            <Route path='/opportunities/add-opportunities' element={<AddOpportunity />} />
            <Route path='/opportunities/opportunities-details' element={<OpportunityDetails />} />
            <Route path='/opportunities/edit-opportunities' element={<EditOpportunities />} />
            <Route path='/opportunities/opportunities-edit' element={<EditOpportunityId />} />
            <Route path='/accounts/account-details' element={<AccountDetails />} />
            <Route path='/accounts/add-accounts' element={<AddAccount />} />
            <Route path='/accounts/account-edit' element={<EditAccounts />} />
            <Route path='/accounts/edit-accounts' element={<EditAccount />} />
            <Route path='/cases' element={<Cases />} />
            <Route path='/cases/case-details' element={<CasesDetails />} />
            <Route path='/cases/add-cases' element={<AddCases />} />
            <Route path='/cases/edit-cases' element={<EditCases />} />
            <Route path='/cases/edit-case' element={<EditCase />} />
            <Route path='/users/add-users' element={<AddUsers />} />
            <Route path='/users/edit-users' element={<EditUser />} /> */}
          </Box>
        </MyContext.Provider>
        <OrganizationModal
          open={organizationModal}
          handleClose={organizationModalClose}
        />
      </Box>
    </>
  );
}
