import React, { useEffect, useState } from 'react'
import {
    TextField,
    AccordionDetails,
    Accordion,
    AccordionSummary,
    Typography,
    Box,
    FormControl,
    Autocomplete,
    Chip,
    IconButton,
    FormHelperText,
} from '@mui/material'
import { FaArrowDown } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompaniesUrl, CompanyUrl, ContactUrl, LeadUrl } from '../../services/ApiUrls';
import { CustomAppBar } from '../../components/CustomAppBar';
import { fetchData } from '../../components/FetchData';
import { AntSwitch, CustomSelectField, RequiredTextField } from '../../styles/CssStyled';
import '../../styles/style.css'
import { FaPlus, FaTimes } from 'react-icons/fa';

type FormErrors = {
    name?: string;
    company_owner?: string;
    company_industry?: string;
};

function AddCompany() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState<any>({
        name: "",
        company_domain: "",
        company_owner: "",
        company_industry: "",
        company_type: "",
        city: "",
        address_line: "",
        street: "",
        state: "",
        postal_code: "",
        number_of_employees: "",
        annual_revenue: "", 
        associated_leads: [] 
    })
    const [errors, setErrors] = useState<FormErrors>({});
    const [selectedAssignTo, setSelectedAssignTo] = useState<any[]>([]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        validateAndSubmit();
    };

    const validateAndSubmit = () => {
        const newErrors: FormErrors = {};
        let hasErrors = false;
    
        // Check for required fields
        if (!formData.name.trim()) {
            newErrors.name = 'This field is required';
            hasErrors = true;
        }
    
        if (!formData.company_owner.trim()) {
            newErrors.company_owner = 'This field is required';
            hasErrors = true;
        }
    
        if (!formData.company_industry.trim()) {
            newErrors.company_industry = 'This field is required';
            hasErrors = true;
        }
     
        setErrors(newErrors);
    
        if (!hasErrors) {
            submitForm();
        }
    };

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Token'),
        org: localStorage.getItem('org')
    }
    const submitForm = () => {
        // console.log('Form data:', data);
        const data = {
            name: formData.name,
            company_domain: formData.company_domain,
            company_owner: formData.company_owner,
            company_industry: formData.company_industry,
            company_type: formData.company_type,
            city: formData.city,
            address_line: formData.address_line,
            street: formData.street,
            state: formData.state,
            postal_code: formData.postal_code,
            number_of_employees: formData.number_of_employees ? formData.number_of_employees : null,
            annual_revenue: formData.annual_revenue ? formData.annual_revenue : null, 
            associated_leads: formData.associated_leads 
        }
        fetchData(`${CompaniesUrl}`, 'POST', JSON.stringify(data), headers)
            .then((res: any) => {
                // console.log('Form data:', res);
                if (!res.error) {
                    resetForm();
                    navigate('/app/accounts');
                }
                if (res.error) {
                    // console.log(res);
                    setError(true);
                    //   setErrors(res?.errors?.contact_errors)
                }
            })
            .catch(() => {
            })
    };

    const resetForm = () => {
        setFormData({
            name: "",
            company_domain: "",
            company_owner: "",
            company_industry: "",
            company_type: "",
            city: "",
            address_line: "",
            street: "",
            state: "",
            postal_code: "",
            number_of_employees: "",
            annual_revenue: "", 
            associated_leads: []  
        });
        setErrors({});
        navigate('/app/accounts');
    }
    const backbtnHandle = () => {
        navigate('/app/accounts')
    }
    const module = 'Accounts'
    const crntPage = 'Add Account'
    const backBtn = 'Back To Accounts'

    const onCancel = () => {
        resetForm()
    }
    // console.log(errors, 'err')

    const handleChange2 = (title: any, val: any) => { 
        if (title === 'assigned_to') {
            setFormData({
                ...formData,
                associated_leads: val.length > 0 ? val.map((item: any) => item?.id) : [],
            });
            setSelectedAssignTo(val);
        } 
    };

    
    return (
        <Box sx={{ mt: '60px' }}>
            <CustomAppBar backbtnHandle={backbtnHandle} module={module} backBtn={backBtn} crntPage={crntPage} onCancel={onCancel} onSubmit={handleSubmit} />
            <Box sx={{ mt: "100px" }}>
                <form onSubmit={handleSubmit}>
                    <div style={{ padding: '10px' }}>
                        {/* Account Details */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <Accordion defaultExpanded style={{ width: '98%' }}>
                                <AccordionSummary expandIcon={<FaArrowDown />}>
                                <div
                                    style={{
                                    borderBottom: '1px solid lightgray',
                                    width: '100%',
                                    }}
                                >
                                    <Typography
                                    style={{
                                        marginBottom: '15px',
                                        fontWeight: 'bold',
                                        color: '#1A3353',
                                    }}
                                    >
                                    Account Details
                                    </Typography>
                                </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{ width: '98%', color: '#1A3353',mb:1 }}
                                        component='form'
                                        // noValidate
                                        autoComplete='off'
                                    >
                                                      
                                        <div className="fieldContainer" style={{ display: 'flex', justifyContent: 'space-between'}}>   
                                            <div className="fieldSubContainer" style={{width: '45%'}}>
                                                <div className="fieldTitle">Company name</div>
                                                <RequiredTextField
                                                    name="name"
                                                    required
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size="small"
                                                    helperText={errors.name ? errors.name : ''}
                                                    error={Boolean(errors.name)}
                                                />
                                
                                            </div>

                                            <div className='fieldSubContainer' style={{width: '45%'}}>
                                                <div className='fieldTitle'>Company domain name</div>
                                                <TextField
                                                    name='company_domain'
                                                    value={formData.company_domain}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size='small'
                                                />
                                            </div>
                                        </div>

                                        <div className="fieldContainer2"  style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div className="fieldSubContainer" style={{width: '45%'}}>
                                                <div className="fieldTitle">Company owner</div>
                                                <RequiredTextField
                                                    name="company_owner"
                                                    type='text'
                                                    value={formData.company_owner}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size="small"
                                                    helperText={errors.company_owner ? errors.company_owner : ''}
                                                    error={Boolean(errors.company_owner)}
                                                />
                                            </div>

                                            <div className="fieldSubContainer" style={{width: '45%'}}>
                                                <div className="fieldTitle">Industry</div>
                                                <RequiredTextField
                                                    name="company_industry"
                                                    type="text"
                                                    value={formData.company_industry}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size="small"
                                                    helperText={errors.company_industry ? errors.company_industry : ''}
                                                    error={Boolean(errors.company_industry)}
                                                />
                                            </div>
                                        </div>

                                        <div className="fieldContainer2"  style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div className="fieldSubContainer" style={{width: '45%'}}>
                                                <div className="fieldTitle">Type</div>
                                                <TextField
                                                    name="company_type"
                                                    type="text"
                                                    value={formData.company_type}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size="small"      
                                                />
                                            </div>

                                            <div className="fieldSubContainer" style={{width: '45%'}}>
                                                <div className="fieldTitle">Number of employees</div>
                                                <TextField
                                                    name="number_of_employees"
                                                    type="number"
                                                    value={formData.number_of_employees}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size="small"      
                                                />
                                            </div>
                                        </div>

                                        <div className="fieldContainer2" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div className="fieldSubContainer" style={{width: '45%'}}>
                                                <div className="fieldTitle">Annual revenue</div>
                                                <TextField
                                                    name="annual_revenue"
                                                    type="text"
                                                    value={formData.annual_revenue}
                                                    onChange={handleChange}
                                                    style={{ width: '70%' }}
                                                    size="small"      
                                                />
                                            </div>

                                            <div className="fieldSubContainer" style={{ display: 'flex', justifyContent: 'space-between', width: '45%'}}>
                                                <div className="fieldTitle">Assign Leads</div>
                                                <FormControl
                                                    sx={{ width: '70%' }}
                                                >
                                                    <Autocomplete
                                                        // ref={autocompleteRef}
                                                        multiple
                                                        value={selectedAssignTo}
                                                        // name='contacts'
                                                        limitTags={2}
                                                        options={state.assignLeads}
                                                        // options={state.contacts ? state.contacts.map((option: any) => option) : ['']}
                                                        getOptionLabel= {(option: any) =>
                                                            option?.title
                                                        }
                                                        onChange={(e: any, value: any) =>
                                                            handleChange2('assigned_to', value)
                                                        }
                                                        size="small"
                                                        filterSelectedOptions
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip
                                                                    deleteIcon={
                                                                        <FaTimes style={{ width: '9px' }} />
                                                                    }
                                                                    sx={{
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                                        height: '18px',
                                                                    }}
                                                                    variant="outlined"
                                                                    label={option?.title}
                                                                    {...getTagProps({ index })}
                                                                />
                                                        ))
                                                        }
                                                        popupIcon={ 
                                                        <IconButton
                                                            sx={{
                                                                width: '45px',
                                                                height: '40px',
                                                                borderRadius: '0px',
                                                                backgroundColor: '#d3d3d34a',
                                                            }}
                                                        >
                                                            <FaPlus style={{ width: '15px' }} />
                                                        </IconButton>
                                                        }
                                                        renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Add Leads"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                sx: {
                                                                    '& .MuiAutocomplete-endAdornment': {
                                                                    mt: '-9px',
                                                                    mr: '-8px',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        )}
                                                    />
                                                {/* <FormHelperText>{errors?.assigned_to?.[0] || ''}</FormHelperText> */}
                                                </FormControl>
                                            </div>
                                        </div> 
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </div>

                        {/* Address */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                        <Accordion defaultExpanded style={{ width: '98%' }}>
                            <AccordionSummary expandIcon={<FaArrowDown />}>
                            <div
                                style={{
                                borderBottom: '1px solid lightgray',
                                width: '100%',
                                }}
                            >
                                <Typography
                                style={{
                                    marginBottom: '15px',
                                    fontWeight: 'bold',
                                    color: '#1A3353',
                                }}
                                >
                                Address
                                </Typography>
                            </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Box
                                sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <div className="fieldContainer" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="fieldSubContainer" style={{width: '45%'}}>
                                        <div className="fieldTitle">Street Address</div>
                                        <TextField
                                            name="street"
                                            type="text"
                                            value={formData.street}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                            size="small"
                                        />
                                    </div>

                                    <div className="fieldSubContainer" style={{width: '45%'}}>
                                        <div className="fieldTitle">Address 2</div>
                                        <TextField
                                            name="address_line"
                                            value={formData.address_line}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                            size="small"
                                        />
                                    </div>
                                </div>

                                <div className="fieldContainer2" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="fieldSubContainer" style={{width: '45%'}}>
                                        <div className="fieldTitle">City</div>
                                        <TextField
                                            name="city"
                                            type="text"
                                            value={formData.city}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                            size="small"
                                        />
                                    </div>

                                    <div className="fieldSubContainer" style={{width: '45%'}}>
                                        <div className="fieldTitle">Zip code</div>
                                        <TextField
                                            name="postal_code"
                                            value={formData.postal_code}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                            size="small"
                                        />
                                    </div>
                                </div>

                                <div className="fieldContainer2" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="fieldSubContainer" style={{width: '45%'}}>
                                        <div className="fieldTitle">State/Region</div>
                                        <TextField
                                            name="state"
                                            type="text"
                                            value={formData.state}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                            size="small"                                        
                                        />
                                    </div>   
                                
                                    <div className="fieldSubContainer">
                                        <div style={{width: '45%'}}></div>
                                    </div>
                                </div>
                            </Box>
                            </AccordionDetails>
                        </Accordion>
                        </div>
                    </div>
                </form>
            </Box>
        </Box>
    )
}

export default AddCompany