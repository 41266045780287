
import React, { useEffect, useState } from 'react'
import {
    Card,
    Box
} from '@mui/material'
import { CustomAppBar } from '../../components/CustomAppBar'
import { useLocation, useNavigate } from 'react-router-dom'
import { CompanyUrl, ContactUrl } from '../../services/ApiUrls'
import { fetchData } from '../../components/FetchData'


export default function CompanyDetails() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [companyDetails, setCompanyDetails] = useState<any>({
        name: "",
        company_domain: "",
        company_owner: "",
        company_industry: "",
        company_type: "",
        city: "",
        street: "",
        address_line: "",
        state: "",
        postal_code: "",
        number_of_employees: "",
        annual_revenue: "",
        associated_leads: [],
        associated_leads_names: []    
    })

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Token'),
        org: localStorage.getItem('org')
    }

    useEffect(() => {
        getCompanyDetail(state?.companyId?.id)
    }, [state?.companyId?.id])

    const getCompanyDetail = (id: any) => {
        fetchData(`${CompanyUrl}/${id}`, 'GET', null as any, headers)
            .then((res) => {
                // console.log(res, 'res');
                if (!res.error) {
                    setCompanyDetails({
                        name: res?.data?.name,
                        company_domain: res?.data?.company_domain,
                        company_owner: res?.data?.company_owner,
                        company_industry: res?.data?.company_industry,
                        company_type: res?.data?.company_type,
                        city: res?.data?.city,
                        street: res?.data?.street,
                        address_line: res?.data?.address_line,
                        state: res?.data?.state,
                        postal_code: res?.data?.postal_code,
                        number_of_employees: res?.data?.number_of_employees,
                        annual_revenue: res?.data?.annual_revenue,
                        associated_leads: res?.data?.associated_leads,
                        associated_leads_names: res?.data?.associated_leads_names   
                    })
                }
            })
    }

    const backbtnHandle = () => {
        navigate('/app/accounts')
    }

    const editHandle = () => {
        navigate('/app/accounts/edit-account', {
            state: { value: { data: companyDetails }, id: state?.companyId?.id, assignLeads: state?.assignLeads }
        })
    }

    const module = 'Accounts'
    const crntPage = 'Account Detail'
    const backBtn = 'Back To Accounts'
    // console.log(state, 'Companies');

    return (
        <Box sx={{ mt: '60px' }}>
            <div>
                <CustomAppBar backbtnHandle={backbtnHandle} module={module} backBtn={backBtn} crntPage={crntPage} editHandle={editHandle} />
                <Box sx={{ mt: '100px', p: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '100%' }}>

                        {/* Account details */}
                        <Card>
                            <div style={{ padding: '15px', borderBottom: '1px solid lightgray', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className='leadDetailsContainer'>
                                <div style={{ fontWeight: 'bold', fontSize: '16px', color: 'rgb(26, 51, 83)' }}>
                                    Account Details
                                </div>
                            </div>
                            <div style={{ padding: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }} className='leadsDetailsResponsive'>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Company name</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.name|| '---'}
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Company domain name</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.company_domain|| '---'}
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Company owner</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.company_owner|| '---'}
                                    </div>
                                </div>
                            </div>

                            <div style={{ padding: '20px', marginTop: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className='leadsDetailsResponsive'>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Industry</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.company_industry || '---'}                                    
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Type</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.company_type || '---'}                                    
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Number of employees</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.number_of_employees || '---'}
                                    </div>
                                </div>
                               
                            </div>

                            <div style={{ padding: '20px', marginTop: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className='leadsDetailsResponsive'>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Annual revenue</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.annual_revenue || '---'}                                       
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Assign Leads</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                    
                                       { companyDetails?.associated_leads_names?.map((item: any) => (
                                            <div>{item[1]}</div>
                                        )) || '---'}
                                    
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                   
                                </div>
                            </div>
                        </Card>

                        {/* Address details */}
                        <Card style={{ borderRadius: '7px', marginTop: '3%' }}>
                            <div style={{ padding: '15px', borderBottom: '1px solid lightgray', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '16px', color: 'rgb(26, 51, 83)' }}>
                                    Address Details
                                </div>
                            </div>

                            <div style={{ padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }} className='leadsDetailsResponsive'>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Street Address</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.street || '---'}
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Address 2</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.address_line || '---'}
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>City</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.city || '---'}
                                    </div>
                                </div>
                            </div>

                            <div style={{ padding: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }} className='leadsDetailsResponsive'>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>Zip code</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.postal_code || '---'}
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}>State/Region</div>
                                    <div style={{ fontSize: '16px', color: 'gray', marginTop: '5%' }}>
                                        {companyDetails?.state || '---'}
                                    </div>
                                </div>
                                <div style={{ width: '32%' }}>
                                    
                                </div>
                            </div>
                        </Card>
                    </Box>
                </Box>
            </div>
        </Box>
    )
}